<template>
	<div class="container" style="background: #fff;">
		<div style="padding: 20px;display: flex;align-items: center;">
			<div class="filter-item">
				<label class="label" style="display: inline-block;width: 90px;">商品名称:</label>
				<el-input clearable v-model="searchKey" style="width: 250px;" placeholder="请填写商品名称"></el-input>
			</div>
			<el-button type="primary" style="width:90px;margin-left: 20px;margin-right:20px;" @click="handleFilter">搜索</el-button>
			<button-permissions :datas="'addReunion'">
				<el-button type="primary" style="width:130px;margin-right:20px;" @click="handleAddReunion()">添加复团商品</el-button>
			</button-permissions>
			<button-permissions :datas="'delReunion'">
				<el-button type="warning" style="width:100px;margin-right:20px;" @click="delReunion({},1)">批量删除</el-button>
			</button-permissions>
		</div>
		<div class="table-container" style="padding: 0 20px;">
			<el-table :data="groupList" style="width: 100%;" :row-key="getRowKeys" @selection-change="handleSelectionChange"
			v-loading="listLoading">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="goodsName" label="商品名称"></el-table-column>
				<el-table-column prop="num" label="点赞数" sortable></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="300px">
					<template slot-scope="scope">
						<div>
							<button-permissions :datas="'deleteReunion'">
								<el-button type="text" @click="delReunion(scope.row,2)">删除</el-button>
							</button-permissions>
						</div>

					</template>
				</el-table-column>
			</el-table>
		</div>
		<div style="display: flex;justify-content: flex-end;">
			<el-pagination v-if="total" style="padding:20px 0;margin-right: 30px;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1400px" class="dialog">
			<select-produce api="afterGroupProductList" :params="['goodsName', 'barCode']"
			@getSelectList="getSelectList" :visible.sync="selectProDialog" v-if="selectProDialog"></select-produce>
		</el-dialog>
	</div>
</template>

<script>
	import selectProduce from './selectMultiPro.vue'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		afterGroupfindPage,
		afterGroupadd,
		afterGroupdel,
	} from '@/api/wyUsedInterface'
	export default {
		components: {
			buttonPermissions,
			selectProduce
		},
		data() {
			return {
				groupList: [],
				listLoading: false,
				searchKey: '',
				pageNo: 1,
				pageSize: 20,
				total: 0,
				multipleSelection: [],//列表多选
				selectProDialog: false,//商品选中弹窗
			}
		},
		created() {
			this.initData();
		},
		methods: {
			async initData() {
				try {
					this.listLoading = true;
					let data = {
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						goodsName: this.searchKey,
						type:1,//1降序,2升序
					};
					let res = await afterGroupfindPage(data);
					this.groupList = res.data.records;
					this.total = res.data.total;
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.listLoading = false;
				}
			},
			//搜索
			handleFilter() {
				this.pageNo = 1;
				this.initData();
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.initData();
			},
			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageNo = val;
				this.initData();
			},
			//表格多选
			handleSelectionChange(val) {
				console.log('----------', val);
				this.multipleSelection = val;
			},
			getRowKeys(row) {
				return row.id;
			},
			//添加复团商品按钮
			handleAddReunion() {
				this.selectProDialog = true;
			},
			//删除确认
			delReunion(obj, type) {
				if (type == 1) {
					if (this.multipleSelection.length == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择要删除的复团商品'
						});
						return;
					}
				}
				this.$alert('确定删除所选复团商品吗', '温馨提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: action => {
						if (action == 'confirm') {
							var ids = [];
							if (type == 1) {
								this.multipleSelection.map(item=>{
									ids.push(item.id)
									return item;
								})
								this.handleDelBatch(ids);
							} else {
								ids.push(obj.id)
								this.handleDelBatch(ids);
							}
						}
					}
				});
			},
			//获取选择商品的列表
			async getSelectList(data) {
				console.log("---data----",data)
				if(!data.length){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择商品'
					});
					return;
				}
				var oldId=[];
				var newId = [];
				this.groupList.map(item=>{
					oldId.push(item.id);
					return item;
				})
				data.map(item=>{
					newId.push(item.id);
					return item;
				})
				
				var list = [];
				list = newId.filter(item=>{
					return oldId.indexOf(item)<0;
				})
				console.log("-----list-----",list)
				this.selectProDialog = false;
				// var ids = [];
				// data.map(item=>{
				// 	ids.push(item.id)
				// 	return item;
				// })
				try {
					this.listLoading = true;
					// let data = {
					// 	goodsIds: ids,
					// };
					let res = await afterGroupadd(list);
					if(res.success){
						this.$message({
							showClose: true,
							type: 'success',
							message: '复团商品添加成功'
						});
						this.initData();
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.listLoading = false;
				}
				// this.groupList = data;
			},
			//批量或单个删除
			async handleDelBatch(ids){
				try {
					
					this.listLoading = true;
					// let data = {
					// 	goodsIds: ids,
					// };
					let res = await afterGroupdel(ids);
					if(res.success){
						this.$message({
							showClose: true,
							type: 'success',
							message: '复团商品删除成功'
						});
						this.initData();
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.listLoading = false;
				}
			},
		}
	}
</script>

<style scoped lang="less">

</style>
